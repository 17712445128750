const HeadingOffer = () => {
    return (
      <div className="flex lg:items-center lg:justify-between mt-5 mb-2">
        <div className="flex-1 min-w-0">
          <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Bogata oferta</h2>
        </div>
      </div>
      
    )
  }
  
  export default HeadingOffer;