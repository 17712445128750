/* This example requires Tailwind CSS v2.0+ */
import {
  LinkIcon,
} from '@heroicons/react/solid'


const HeadingPortfolio = () => {
  return (
    <div className="flex lg:items-center lg:justify-between mt-5 mb-2">
      <div className="flex-1 min-w-0">
        <h2 className="text-xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Ostatnie realizacje</h2>
      </div>
      <div className="flex lg:mt-0 lg:ml-4">

        <span className="hidden sm:block ml-3">
          <button
            type="button"
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            aria-hidden="true" data-bs-toggle="modal" data-bs-target="#loadMore"
          >
            <LinkIcon className="-ml-1 mr-2 h-5 w-5 text-gray-500" />
            zobacz więcej
          </button>
        </span>
      </div>
    </div>
    
  )
}

export default HeadingPortfolio;