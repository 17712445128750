import React, { useEffect } from "react";
import app from "../admin/env_base";

import Header from "./modules/header";
import HeadingPortfolio from "./modules/portfolio-heading";
import Contact from "./modules/contact";
import HeadingCoop from "./modules/coop-heading";
import HeadingOffer from "./modules/offer-heading";
import HeadingContact from "./modules/contact-heading"

const Home = () => {

  const db = app.firestore();
  const [users, setUsers] = React.useState([]);
  
  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = db.collection("users");
      const usersCollection = await usersRef.orderBy("date", "desc").limit(25).get();
      setUsers(
        usersCollection.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    fetchUsers();
  }, []);

  return (
    <>
      <Header></Header>
      


{/* Main content */}
{/* ------------ */}
<div className="container">

<div className="shadow p-3 roudned-3">
<HeadingPortfolio></HeadingPortfolio>
<hr></hr>
<div className="row row-cols-1 row-cols-md-3 g-4">
{users.slice(0, 3).map((user) => {
          return (
  <div className="col" key={user.name}>
  <div className="card mt-3">
    <img src={user.name} className="card-img-top" alt="Realizacja"/>
  </div>
  </div>
    );
})}
</div>
</div>

{/* Okna Modal */}
<div className="modal fade" id="loadMore" tabindex="-1" aria-hidden="true">
  <div className="modal-dialog">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Więcej realizacji</h5>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
      <div className="row row-cols-1">
{users.map((user) => {
          return (
  <div className="col" key={user.name}>
  <div className="card mt-3">
    <img src={user.name} className="card-img-top" alt="Realizacja"/>
  </div>
  </div>
    );
})}
</div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Zamknij</button>
      </div>
    </div>
  </div>
</div>

{/* Cooperations */}
<div className="shadow p-3 rounded-3">
<HeadingCoop></HeadingCoop>
<hr></hr>
<div className="row row-cols-2 row-cols-sm-3 row-cols-lg-6 g-4 mt-1">
    <img className="col" src="./coop/petecki.jpg" alt="Coop"></img>
    <img className="col" src="./coop/dre.jpg" alt="Coop"></img>
    <img className="col" src="./coop/wis.jpg" alt="Coop"></img>
    <img className="col" src="./coop/portos.jpg" alt="Coop"></img>
    <img className="col" src="./coop/verte.jpg" alt="Coop"></img>
    <img className="col" src="./coop/voster.jpg" alt="Coop"></img>
</div>
</div>

{/* Offer */}
<div className="shadow p-3 rounded-3">
<HeadingOffer></HeadingOffer>
<hr></hr>
<div className="row row-cols-1 row-cols-md-3 g-4 mt-1" id="offer">
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/1.png" className="card-img-top" alt="Okna"/>
    <div className="card-body">
      <h5 className="card-title">Okna</h5>
      <p className="card-text">Okna to bardzo ważny,  jeśli nie najważniejszy element domu czy mieszkania. W swojej ofercie posiadamy okna z PCV, drewna, aluminium oraz okna dachowe.</p>
    </div>
  </div>
  </div>
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/2.png" className="card-img-top" alt="Drzwi"/>
    <div className="card-body">
      <h5 className="card-title">Drzwi</h5>
      <p className="card-text">Drzwi wejściowe są wizytówką naszego domu, dlatego tak ważny jest ich wygląd i estetyka wykonania. W naszej ofercie znajdą Państwo drzwi:  PCV, drewniane, stalowe i aluminiowe.</p>
    </div>
  </div>
  </div>
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/3.png" className="card-img-top" alt="Bramy"/>
    <div className="card-body">
      <h5 className="card-title">Bramy</h5>
      <p className="card-text">Bramy, obok okien i drzwi, stanowią ważny element stolarki otworowej. Każdą bramę można wyposażyć w automatykę, co ułatwi nam jej otwieranie  za pomocą pilota.</p>
    </div>
  </div>
  </div>
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/4.png" className="card-img-top" alt="Rolety"/>
    <div className="card-body">
      <h5 className="card-title">Rolety</h5>
      <p className="card-text">Spełniają wiele funkcji: chronią nas przed hałasem z zewnątrz,  stanowią doskonały element dekoracyjny dopasowany do wyglądu budynku oraz stanowią doskonała izolację termiczną. Gwarantują nie tylko większe bezpieczeństwo, ale również chronią przed nadmiernym słońcem, wiatrem i zimnem.</p>
    </div>
  </div>
  </div>
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/5.png" className="card-img-top" alt="Parapety"/>
    <div className="card-body">
      <h5 className="card-title">Parapety</h5>
      <p className="card-text">Parapety zewnętrzne powinny nie tylko ładnie wyglądać, ale także być odporne na warunki atmosferyczne czyli wysokie i niskie temperatury oraz duże nasłonecznienie. W naszej ofercie posiadamy parapety zewnętrzne wykonane z: granitu, stalowe lub aluminiowe.</p>
    </div>
  </div>
  </div>
  <div className="col">
  <div className="card m-1">
    <img src="./oferta/6.png" className="card-img-top" alt="Osłony"/>
    <div className="card-body">
      <h5 className="card-title">Osłony okienne</h5>
      <p className="card-text">To proste i niezawodne  rozwiązanie: do regulacji oświetlenia, dekoracji oraz komfortu intymności w każdym wnętrzu. W ofercie posiadamy roletki materiałowe: wolnowiszące, w kasetach, rzymskie, dzień i noc.</p>
    </div>
  </div>
  </div>
</div>
</div>

<HeadingContact></HeadingContact>
<hr id="contact"></hr>
<Contact ></Contact>


    </div>
    </>
  );
};

export default Home;
