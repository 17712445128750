import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Admin from "./admin/Admin";
import Login from "./admin/Login";

import { AuthProvider } from "./admin/Auth";
import PrivateRoute from "./PrivateRoute";

import Home from "./web/Home";
import Images from "./web/Images";
import PageNotFound from "./web/404";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/images" component={Images} />

          <PrivateRoute exact path="/admin" component={Admin} />
          <Route exact path="/admin/login" component={Login} />
          
          <Route component={PageNotFound} />
        </Switch>
      </Router>
    </AuthProvider>
  );
};

export default App;
