/* This example requires Tailwind CSS v2.0+ */
import { PaperClipIcon } from '@heroicons/react/solid'

const Contact = () => {
  return (
    <div className="mt-5 bg-white shadow overflow-hidden sm:rounded-lg">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">Kontakt</h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">Odwiedź nas lub napisz na adres e-mal aby uzyskać ofertę.</p>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Zapytania ofertowe - napisz lub zadzwoń:</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2"><a href="mailto:info.venter@wp.pl" className="btn btn-sm btn-primary">Wyślij wiadomość </a> <i>lub ręcznie:</i> <b>info.venter@wp.pl</b></dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Telefon</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">669-567-750 lub 609-534-839</dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Adres:</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">ul. Nowa 38C, 63-200 Cielcza</dd>
          </div>
          <div className="bg-white px-4 py-5 ">
            <iframe title="Mapa Venter" className="w-100 rounded-3" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2455.767606573518!2d17.484685516170103!3d52.011112479720985!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x470523c2307f54a5%3A0x27735c6cd31e7377!2sVenter!5e0!3m2!1spl!2spl!4v1626431061445!5m2!1spl!2spl" height="400" allowfullscreen="true" loading="lazy"></iframe>
          </div>
        </dl>
      </div>
    </div>
  )
}

export default Contact;