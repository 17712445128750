import React from "react";

const PageNotFound = () => {
  return (
    <>
      <h1>404 Err</h1>
    </>
  );
};

export default PageNotFound;
