import * as firebase from "firebase/app";
import "firebase/auth";
import 'firebase/storage';
import 'firebase/firestore';

const app = firebase.initializeApp({
  "projectId": "venter-website",
  "appId": "1:574580013930:web:5a29dda6c174b68134c313",
  "databaseURL": "https://venter-website.firebaseio.com",
  "storageBucket": "venter-website.appspot.com",
  "locationId": "eur3",
  "apiKey": "AIzaSyDTyg9urFFafrPiNUi4LdFnkN3uZHpuGjc",
  "authDomain": "venter-website.firebaseapp.com",
  "messagingSenderId": "574580013930"
});

export default app;

