
import React, { useEffect, useState } from "react";
import app from "./env_base";

import AdminNav from "./modules/admin-navbar";

const db = app.firestore();

const Admin = () => {

  const [users, setUsers] = React.useState([]);
  const [allImages, setImages] = useState([]);

  const onFileChange = async (e) => {
    const date = Date.now();
    const file = e.target.files[0];
    const storageRef = app.storage().ref();
    const fileRef = storageRef.child(Math.random().toString(36).substring(7));
    await fileRef.put(file);
    const fileUrl = await fileRef.getDownloadURL();
    const username = Math.random().toString(36).substring(7);
    await db.collection("users").doc(username).set({
      name: fileUrl,
      id: username,
      date: date
    });
    alert("Przesłano plik. Strona zostanie odświeżona.");
    window.location.reload();
  };

  useEffect(() => {
    const fetchUsers = async () => {
      const usersRef = db.collection("users");
      const usersCollection = await usersRef.orderBy("date", "desc").get();
      setUsers(
        usersCollection.docs.map((doc) => {
          return doc.data();
        })
      );
    };
    fetchUsers();
  }, []);

  const deleteFromFirebase = async (user) => {
    let pictureRef = app.storage().refFromURL(user.name);
    await db.collection("users").doc(user.id).delete().then(() => {
    }).catch((error) => {
      console.error("Wystąpił błąd: ", error);
    });
    await pictureRef
      .delete()
      .then(() => {
        setImages(allImages.filter((image) => image !== user.name));
        alert("Usunięto pomyslnie! Strona zostanie odświeżona.");
        window.location.reload();
      })
      .catch((err) => {
        alert("Wystąpił błąd: ", err);
        window.location.reload();
      });
  };

  const log = (user) => {
    console.log(user)
  };

  return (
    <>

    <AdminNav />
    <div className="container">

<div className="d-flex justify-content-between">
<div class="offcanvas offcanvas-bottom" tabindex="-1" id="offcanvasBottom" aria-labelledby="offcanvasBottomLabel">
  <div class="offcanvas-header">
    <h5 class="offcanvas-title" id="offcanvasBottomLabel">Dodaj zdjęcie</h5>
    <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
  </div>
  <div class="offcanvas-body small">
      <form className="alert alert-light border-0">
        <button type="submit" className="btn btn-dark mb-3 w-100" disabled>Zdjęcie zostanie dodane automatycznie po wybraniu</button>
        <div className="mb-3">
          <label htmlFor="formFile" className="form-label text-center"><b>Wybierz zdjęcie (.jpg lub .png):</b></label>
          <input className="form-control" type="file" id="formFile" onChange={onFileChange} />
        </div>

      </form>
  </div>
</div>
</div>
<hr></hr>


<div className="row row-cols-1 row-cols-md-4 p-3">
      {users.map((user) => {
          return (
            <div className="card border-1 p-0" key={user.name}>
              <img className="img-responsive m-0" src={user.name} alt="Venter - realizacje" />
              <div class="card-footer w-100 border-1 m-0">
              <div class="dropdown">
                <button class="btn btn-light dropdown-toggle w-100" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                  Wyświetl opcje
                </button>
               <ul class="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                  <li><button className="btn btn-sm btn-danger w-100" onClick={() => deleteFromFirebase(user)}>Usuń</button></li>
              </ul>
              </div>
                
              </div>
            </div>
          );
        })}
    </div>
    </div>
    </>
  );

  
};
export default Admin;