import React from "react";

const Images = () => {
  return (
    <>
      <h1>All images</h1>
    </>
  );
};

export default Images;
